@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('//fonts.googleapis.com/css?family=Oswald');

$color-primary: #1bbbc6;
$color-white: #f5f5f5;
$color-black: #1c1c1c;
$color-footer: $color-black;
$screen-md-min: 768px;

$content-width: 100rem;

@layer base {
  body {
    @apply text-base;
  }

  h1 {
    @apply text-4xl;
  }

  section {
    @apply flex;
    @apply flex-col;
    @apply justify-center;
    @apply items-center;
    @apply text-lg;

    @apply box-border;
    @apply leading-8;
  }

  header {
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.95);
  }

  #hero {
    margin-top: -5em;
    padding-top: 15em;
    border-bottom: 1px solid rgba(212, 225, 227, 0.1);
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 60%;

      background: url("./assets/open-laptop.jpg");
      background-repeat: no-repeat;

      z-index: -1;

      background-size: cover;
      background-position: center center;

      mask-image: linear-gradient(
        2deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 4%,
        rgba(0, 0, 0, 1) 4.1%,
        rgba(0, 0, 0, 1) 100%
      );
    }
  }

    @media (max-width: #{$screen-md-min}) {
      #hero {
        &::after {
          width: 100%;
        }
      }
    }


  #contact-header {
    margin-top: -5em;
    padding-top: 15em;
    border-bottom: 1px solid rgba(212, 225, 227, 0.1);
    position: relative;
    height: 22em;

    &::before{
      content: "";
      position: absolute;
      left: 0; right: 0;
      top: 0; bottom: 0;
      background: rgba(0,0,0,.5);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;

      background: url("images/stockimages/julian-hochgesang-psGV5KhidlY-unsplash.jpg");
      background-repeat: no-repeat;

      z-index: -1;

      background-size: cover;
      background-position: center center;

    }
  }

  #career-header {
    margin-top: -5em;
    padding-top: 15em;
    border-bottom: 1px solid rgba(212, 225, 227, 0.1);
    position: relative;
    height: 22em;

    &::before{
      content: "";
      position: absolute;
      left: 0; right: 0;
      top: 0; bottom: 0;
      background: rgba(0,0,0,.5);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;

      background: url("images/stockimages/priscilla-du-preez-XkKCui44iM0-unsplash - Edited.png");
      background-repeat: no-repeat;

      z-index: -1;

      background-size: cover;
      background-position: center center;

    }
  }

  #careers {
    position: relative;
    background: transparent;
    backdrop-filter: blur(30px);
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      background: linear-gradient(
          90deg,
          rgba(11, 11, 11, 1) 20%,
          rgba(11, 11, 11, 0.1) 100%
        ),
        url("./assets/macbook-on-table.jpg"), fixed, center;

      background-attachment: fixed;
      background-position: 50% 0%;
      z-index: -1;
    }
  }
}

#join {
  position: relative;
  background: transparent;
  backdrop-filter: blur(30px);
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    background: linear-gradient(
        90deg,
        rgba(11, 11, 11, 1) 40%,
        rgba(11, 11, 11, 0.1) 100%
      ),
      url("./images/stockimages/marvin-meyer-SYTO3xs06fU-unsplash.jpg"), fixed, center;

    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    z-index: -1;
  }
}

@layer components {
  .btn-primary {
    @apply text-base;
    @apply w-fit;
    @apply px-12;
    @apply py-4;
    @apply font-semibold;
    @apply bg-cyan-400;
    @apply text-black;

    &:hover {
      @apply transition-colors;
      @apply bg-cyan-600;
      @apply text-white;
    }
  }
}

@layer components {
  .btn-submit {
    @apply text-base;
    @apply w-fit;
    @apply px-5;
    @apply py-2;
    @apply font-semibold;
    @apply bg-cyan-500;
    @apply text-white;

    &:hover {
      @apply transition-colors;
      @apply bg-black;
    }

    &:focus {
      @apply transition-colors;
      @apply bg-cyan-500;
    }
  }
}

.text-gradient {
  background: radial-gradient(at right top,  #0d524c, #01eeff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.text-gradient-black {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #444 42.04%,
    #333 55.12%,
    #222 71.54%,
    #222 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

}
.bg-services {
  background: radial-gradient(64.18% 64.18% at 71.16% 35.69%, #111, #1c1c1c);
}


.about-services {
  background: url("./assets/thunderbolt.svg");
  background-repeat: no-repeat;
  background-position: center;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20em;
  height: 20em;
  opacity: 0.3;
}

.text-gradient-white {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #d5d5d5 42.04%,
    #e3e3e3 55.12%,
    #fff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  padding: 0.1em;
}

.service-header {
  &::before {
    position: relative;
    width: 3rem;
    height: 3rem;
    margin-right: .25em;
    content: " ";


    background: radial-gradient(at right top,  #0d524c, #01eeff);
  }
}

/* .mac-table {
  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: url("./assets/macbook-on-table.jpg");
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }
} */

.deploy-header {
  &::before {
    -webkit-mask-image: url("./assets/server-stack.svg");
    mask-image: url("./assets/server-stack.svg");
  }
}

@media (prefers-reduced-motion: no-preference) {
  .square-animation {
    animation: wipe-enter 1.05s 1;
  }
}

.develop-header {
  &::before {
    -webkit-mask-image: url("./assets/command-line.svg");
    mask-image: url("./assets/command-line.svg");
  }
}

.design-header {
  &::before {
    -webkit-mask-image: url("./assets/pencil-square.svg");
    mask-image: url("./assets/pencil-square.svg");
  }
}

.contracts-header {
  &::before {
    -webkit-mask-image: url("./assets/file-circle-xmark-solid.svg");
    mask-image: url("./assets/file-circle-xmark-solid.svg");
    mask-repeat: no-repeat;

  }
}

.team-header {
  &::before {
    -webkit-mask-image: url("./assets/people-group-solid.svg");
    mask-image: url("./assets/people-group-solid.svg");
    mask-repeat: no-repeat;
  }
}

.mentor-header {
  &::before {
    -webkit-mask-image: url("./assets/handshake-angle-solid.svg");
    mask-image: url("./assets/handshake-angle-solid.svg");
    mask-repeat: no-repeat;
  }
}

input[type="text"], input[type="tel"], textarea, input[type="email"] {

  background-color : #E8E8E8;
  width: 100%;
  height: 3em;
  padding: 15px;
  font-size: medium;
  border-radius: 5px;

}

input:focus {
  background-color: white;
}


textarea:focus {
  background-color: white;
}

label{
  margin-bottom: 25px;
  font-weight: 600;
}


.text-6xl {
  line-height: 1.25;
}

@keyframes fadeInDropDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes wipe-enter {
	0% {
		transform: scale(0, .025);
	}
	50% {
		transform: scale(1, .025);
	}
}
