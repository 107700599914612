@import "//fonts.googleapis.com/css?family=Oswald";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

body {
  font-size: 1rem;
  line-height: 1.5rem;
}

h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

section {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  line-height: 2rem;
  display: flex;
}

header {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: #fffffff2;
}

#hero {
  border-bottom: 1px solid #d4e1e31a;
  margin-top: -5em;
  padding-top: 15em;
  position: relative;
}

#hero:after {
  content: "";
  width: 60%;
  z-index: -1;
  background: url("open-laptop.50a91e34.jpeg") center / cover no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  -webkit-mask-image: linear-gradient(2deg, #0000 0% 4%, #000 4.1% 100%);
  mask-image: linear-gradient(2deg, #0000 0% 4%, #000 4.1% 100%);
}

@media (max-width: 768px) {
  #hero:after {
    width: 100%;
  }
}

#contact-header {
  height: 22em;
  border-bottom: 1px solid #d4e1e31a;
  margin-top: -5em;
  padding-top: 15em;
  position: relative;
}

#contact-header:before {
  content: "";
  background: #00000080;
  position: absolute;
  inset: 0;
}

#contact-header:after {
  content: "";
  width: 100%;
  z-index: -1;
  background: url("julian-hochgesang-psGV5KhidlY-unsplash.daaeb4ec.jpeg") center / cover no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

#career-header {
  height: 22em;
  border-bottom: 1px solid #d4e1e31a;
  margin-top: -5em;
  padding-top: 15em;
  position: relative;
}

#career-header:before {
  content: "";
  background: #00000080;
  position: absolute;
  inset: 0;
}

#career-header:after {
  content: "";
  width: 100%;
  z-index: -1;
  background: url("priscilla-du-preez-XkKCui44iM0-unsplash - Edited.453eee1f.png") center / cover no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

#careers {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  z-index: 1;
  background: none;
  position: relative;
}

#careers:after {
  content: "";
  z-index: -1;
  background-color: #0000;
  background-image: linear-gradient(90deg, #0b0b0b 20%, #0b0b0b1a 100%), url("macbook-on-table.039c3505.jpeg"), none, none;
  background-position: 50% 0;
  background-repeat: repeat, repeat, repeat, repeat;
  background-size: auto, auto, auto, auto;
  background-attachment: fixed;
  background-origin: padding-box, padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box, border-box;
  position: absolute;
  inset: 0;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.btn-primary {
  width: -moz-fit-content;
  width: fit-content;
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  padding: 1rem 3rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.btn-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.visible {
  visibility: visible;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.z-40 {
  z-index: 40;
}

.z-10 {
  z-index: 10;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mt-8 {
  margin-top: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.-mt-36 {
  margin-top: -9rem;
}

.mt-12 {
  margin-top: 3rem;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-20 {
  height: 5rem;
}

.h-8 {
  height: 2rem;
}

.h-full {
  height: 100%;
}

.h-4 {
  height: 1rem;
}

.h-28 {
  height: 7rem;
}

.w-36 {
  width: 9rem;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-16 {
  width: 4rem;
}

.w-8 {
  width: 2rem;
}

.w-4 {
  width: 1rem;
}

.w-10\/12 {
  width: 83.3333%;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.flex-1 {
  flex: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.animate-\[0\.75s_ease-in_0s_1_fadeInDropDown\] {
  animation: fadeInDropDown .75s ease-in;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-center {
  justify-items: center;
}

.gap-20 {
  gap: 5rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-y-24 {
  row-gap: 6rem;
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)) );
}

.border-b {
  border-bottom-width: 1px;
}

.border-transparent {
  border-color: #0000;
}

.bg-neutral-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-neutral-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/90 {
  background-color: #ffffffe6;
}

.p-4 {
  padding: 1rem;
}

.p-14 {
  padding: 3.5rem;
}

.p-2 {
  padding: .5rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.align-middle {
  vertical-align: middle;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.leading-tight {
  line-height: 1.25;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-loose {
  line-height: 2;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

.text-neutral-100 {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

.text-neutral-300 {
  --tw-text-opacity: 1;
  color: rgb(212 212 212 / var(--tw-text-opacity));
}

.text-neutral-400 {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity));
}

.text-neutral-800 {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-90 {
  opacity: .9;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-700 {
  transition-duration: .7s;
}

#join {
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  z-index: 1;
  background: none;
  position: relative;
}

#join:after {
  content: "";
  z-index: -1;
  background-color: #0000;
  background-image: linear-gradient(90deg, #0b0b0b 40%, #0b0b0b1a 100%), url("marvin-meyer-SYTO3xs06fU-unsplash.5b81631c.jpeg"), none, none;
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-origin: padding-box, padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box, border-box;
  position: absolute;
  inset: 0;
}

.text-gradient {
  background: radial-gradient(at 100% 0, #0d524c, #01eeff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.text-gradient-black {
  background: radial-gradient(64.18% 64.18% at 71.16% 35.69%, #444 42.04%, #333 55.12%, #222 71.54% 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.bg-services {
  background: radial-gradient(64.18% 64.18% at 71.16% 35.69%, #111, #1c1c1c);
}

.about-services {
  width: 20em;
  height: 20em;
  opacity: .3;
  background: url("thunderbolt.424f5e39.svg") center no-repeat;
  top: 0;
  bottom: 0;
  left: 0;
}

.text-gradient-white {
  background: radial-gradient(64.18% 64.18% at 71.16% 35.69%, #d5d5d5 42.04%, #e3e3e3 55.12%, #fff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  padding: .1em;
}

.service-header:before {
  width: 3rem;
  height: 3rem;
  content: " ";
  background: radial-gradient(at 100% 0, #0d524c, #01eeff);
  margin-right: .25em;
  position: relative;
}

.deploy-header:before {
  -webkit-mask-image: url("server-stack.21316180.svg");
  mask-image: url("server-stack.21316180.svg");
}

@media (prefers-reduced-motion: no-preference) {
  .square-animation {
    animation: wipe-enter 1.05s;
  }
}

.develop-header:before {
  -webkit-mask-image: url("command-line.80409c38.svg");
  mask-image: url("command-line.80409c38.svg");
}

.design-header:before {
  -webkit-mask-image: url("pencil-square.a6a65979.svg");
  mask-image: url("pencil-square.a6a65979.svg");
}

.contracts-header:before {
  -webkit-mask-image: url("file-circle-xmark-solid.08cd0590.svg");
  mask-image: url("file-circle-xmark-solid.08cd0590.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.team-header:before {
  -webkit-mask-image: url("people-group-solid.86aa35ec.svg");
  mask-image: url("people-group-solid.86aa35ec.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.mentor-header:before {
  -webkit-mask-image: url("handshake-angle-solid.2956918d.svg");
  mask-image: url("handshake-angle-solid.2956918d.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

input[type="text"], input[type="tel"], textarea, input[type="email"] {
  width: 100%;
  height: 3em;
  background-color: #e8e8e8;
  border-radius: 5px;
  padding: 15px;
  font-size: medium;
}

input:focus, textarea:focus {
  background-color: #fff;
}

label {
  margin-bottom: 25px;
  font-weight: 600;
}

.text-6xl {
  line-height: 1.25;
}

@keyframes fadeInDropDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes wipe-enter {
  0% {
    transform: scale(0, .025);
  }

  50% {
    transform: scale(1, .025);
  }
}

.hover\:scale-\[1\.08\]:hover {
  --tw-scale-x: 1.08;
  --tw-scale-y: 1.08;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:brightness-150:hover {
  --tw-brightness: brightness(1.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.hover\:filter:hover {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.group:hover .group-hover\:scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (min-width: 640px) {
  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:h-auto {
    height: auto;
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-start {
    text-align: start;
  }

  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (min-width: 768px) {
  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:-mt-0, .md\:mt-0 {
    margin-top: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:p-20 {
    padding: 5rem;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .md\:pl-4 {
    padding-left: 1rem;
  }

  .md\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-start {
    text-align: start;
  }

  .md\:opacity-100 {
    opacity: 1;
  }
}

@media (min-width: 1024px) {
  .lg\:w-2\/5 {
    width: 40%;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

@media (min-width: 1280px) {
  .xl\:w-1\/2 {
    width: 50%;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:w-2\/5 {
    width: 40%;
  }

  .\32 xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

/*# sourceMappingURL=index.4d6f9b5b.css.map */
